<template>
  <div>
    <i
        class="mr-2"
    ></i>
    <div class="d-flex flex-row align-content-center align-items-center">
      <vs-checkbox
          v-model="label.value"
          color="primary"
          @change.stop="$emit('on:check')"
      >
      </vs-checkbox>

      <span class="ml-3 mt-2">
        <label> {{ label.text }} </label>
    </span>
    </div>
  </div>
</template>

<script>
export default {
  props: ['label'],
  created () {
    this.$vs.theme({
      primary:'rgb(0, 165, 216)'
    })
  }
}
</script>
<style lang="scss" scoped>
/deep/ .vs-checkbox--input:checked+.vs-checkbox .vs-icon {
  font-family: 'Material Icons';
}
</style>
